export default {
  props: {
    size: String,
    isSmall: Boolean,
    isMedium: Boolean,
    isLarge: Boolean,
    isFullheight: Boolean,
    isFullwidth: Boolean
  },
  computed: {
    sizeClass() {
      if (this.size)
        return { ["is-" + this.size]: true, "is-fullwidth": this.isFullwidth };
      if (this.isSmall)
        return { "is-small": true, "is-fullwidth": this.isFullwidth };
      if (this.isMedium)
        return { "is-medium": true, "is-fullwidth": this.isFullwidth };
      if (this.isLarge)
        return { "is-large": true, "is-fullwidth": this.isFullwidth };
      if (this.isFullheight)
        return { "is-fullheight": true, "is-fullwidth": this.isFullwidth };
      return {'is-fullwidth': this.isFullwidth};
    },
    sizeTr() {
      if (this.size) return this.size;
      if (this.isSmall) return "small";
      if (this.isMedium) return "medium";
      if (this.isLarge) return "large";
      if (this.isFullheight) return "fullheight";
    }
  }
};
