import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    lang: ''
  },
  getters: {
    lang: (state) => state.lang
  },
  mutations: {
    set (state, {lang}) {
      state.lang = lang;
    }
  },
  actions: {
    update (context, {lang}) {
      if (context.rootGetters['auth/authenticated']) {
        Vue.$api.patch('/user', {lang}).then(() => {
          context.dispatch('notify', {message: 'language_set_success', color: 'success'}, {root: true});
        }).catch((error) => {
          context.dispatch('error', {message: 'language_set_error', error}, {root: true});
        });
      }
      context.dispatch('load', {lang});
    },
    load (context, {lang}) {
      if (Vue.sentry) Vue.sentry.configureScope(scope => scope.setTag('lang', lang));
      context.commit('set', {lang});
      Vue.prototype.$locale.change(lang);
    }
  }
};
