import vue from 'vue';

import uniq from '../utils/uniq';

export default {
  namespaced: true,
  state: {
    byTags: {
      'prospect': {},
      'particulier': {
        RC: ['RC - ménage', 'HIO'],
        Assistance: ['Assistance'],
        Incendie: ['Incendie', 'Incendie Ménage', 'Incendie Bâtiment', 'Incendie Commerce'],
        OV: ['Objets de Valeurs'],
        PJ: ['PJ'],
        Vie: ['Vie']
      },
      'propriétaire': {
        RC: ['RCP', 'HIO', 'RC - ménage'],
        Bat: ['Bâtiment'],
        PH: ['PH'],
        Incendie: ['Incendie'],
        OV: ['Objets de Valeurs'],
        PJ: ['PJ'],
        Vie: ['Vie']
      },

      'investisseur immobilier': {
        RCi: ['RCI'],
        Bat: ['Bâtiment'],
        ph: ['PH'],
        Incendie: ['Incendie', 'Incendie Bâtiment'],
        PJ: ['PJ']
      },

      'constructions / transformations': {
        RCMO: ['RCMO'],
        TC: ['TC']
      },

      'conducteur': {
        VHM: ['VHM'],
        PJ: ['PJ']
      },

      'entreprise': {
        RC: ['RC + Commerce', 'RCE', 'Commerce', 'Combinée Comm.'],
        LAA: ['LAA'],
        AMC: ['AMC'],
        LAAC: ['LAAC'],
        LPP: ['LPP'],
        Caution: ['Caution'],
        Bat: ['Bâtiment'],
        PH: ['PH'],
        VHM: ['VHM'],
        Incendie: ['Incendie', 'Incendie Bâtiment', 'Incendie Commerce'],
        PJ: ['PJ'],
        Bris: ['Bris de Machine'],
        TR: ['Transport'],
        Inst: ['Installations'],
        FIO: ['FIO'],
        Ordi: ['Ordinateurs'],
        Garant: ['Garantie']
      }
    },
    computing: false,
    error: null,
    matches: []
  },
  getters: {
    computing: (state) => state.computing,
    matches: (state) => state.matches,
  },
  mutations: {
    reset (state) {
      state.computing = true;
      state.matches = [];
      state.error = null;
    },
    computed (state, {matches}) {
      state.matches = matches;
      state.computing = false;
    }
  },
  actions: {
    compute (context, {tags, policies}) {
      console.log(tags, policies);
      let targets = tags.map(tag => context.state.byTags[tag]).reduce((acc, curr) => {
        let res = Object.assign({}, acc);
        let accKeys = Object.keys(acc);
        let currKeys = Object.keys(curr || {});
        let commonKeys = currKeys.filter(k => accKeys.includes(k));
        currKeys.forEach(k => {
          if (commonKeys.includes(k)) {
            res[k] = uniq([...acc[k], ...curr[k]]);
          } else {
            res[k] = curr[k];
          }
        });
        return res;
      }, {});

      let policiesActive = policies.filter(p => !p.archived)

      let matches = Object.keys(targets).map((key) => {
        let branches = targets[key];
        let found = policiesActive.filter(p => branches.includes(p.branchAbrev));
        return {key, branches, found};
      });

      context.commit('computed', {matches});
    }
  }
}
