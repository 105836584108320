<template>
  <div class="field">
    <div class="select">
      <select name="lang" @change="change" :value="language">
        <!--<option value="en">English</option>-->
        <!--<option value="de">Deutsch</option>-->
        <option value="fr">Français</option>
      </select>
    </div>
  </div>
</template>

<script>
  import {mapActions} from 'vuex';

  export default {
    name: 'language-picker',
    computed: {
      language () {
        return this.$i18n && this.$i18n.locale;
      },
      locales () {
        return Object.keys(this.$store.state.i18n.translations).map(language => ({
          id: language,
          label: this.$t(language),
          selected: language === this.language
        }));
      }
    },
    mounted () {},
    methods: {
      ...mapActions('language', ['update']),
      change (event) {
        this.update({lang: event.target.value});
      }
    }
  };
</script>
