<template>
  <div class="notification" :class="colorClass">
    <button class="delete" @click="discard({id: notification.id})"></button>
    <span v-show="notification.ttl" class="is-pulled-right"><span class="has-text-weight-light">{{notification.ttl}}</span></span>
    <p class="has-text-weight-semibold" v-if="notification.label">{{$t(notification.label)}}</p>
    <p v-if="notification.message">{{notification.message}}</p>
  </div>

</template>

<script>
import { mapActions } from 'vuex';
import color from '../sqrd/mixins/color';

export default {
  name: 'Notification',
  mixins: [color],
  props: {
    notification: { type: Object, default: () => ({}) }
  },
  methods: {
    ...mapActions('notifications', ['discard'])
  }
};
</script>
