import VueI18n from 'vue-i18n';
import browserLanguage from 'in-browser-language';

export default {
  install (Vue, options) {
    // let locales = ['en', 'de', 'fr'];
    let locales = ['fr'];

    Vue.use(VueI18n);
    const i18n = new VueI18n(options);

    let locale = Vue.store && Vue.store.getters['language/lang'];
    if (!locale) {
      // detect the best locale to use from the browser
      locale = browserLanguage.pick(locales, locales[0]);
    }

    Vue.$locale = i18n;
    Vue.prototype.$locale = {
      change (lang) {
        if (locales.indexOf(lang) < 0) return;
        // i18n.locale = lang;
        Vue.store && Vue.store.commit('language/update', {locale: lang});

        function changeLocale (locale) {
          // console.log(locale.default.locale);
          i18n.setLocaleMessage(lang, locale.default.messages);
          i18n.locale = lang;
          i18n.silentTranslationWarn = false;
        }

        // the resolve are hard coded for webpack to prepare the resolution
        switch (lang) {
          case 'en': import(/* webpackChunkName: "i18n-en" */ '@/i18n/en').then(changeLocale); break;
          case 'de': import(/* webpackChunkName: "i18n-de" */ '@/i18n/de').then(changeLocale); break;
          case 'fr': import(/* webpackChunkName: "i18n-fr" */ '@/i18n/fr').then(changeLocale); break;
        }
      },
      current () {
        return i18n.locale;
      }
    };

    Vue.prototype.$locale.change(locale);

    if (module.hot) {
      module.hot.accept([
        '../i18n/en',
        '../i18n/de',
        '../i18n/fr'
      ], function () {
        i18n.setLocaleMessage('en', require('../i18n/en').default.messages);
        i18n.setLocaleMessage('de', require('../i18n/de').default.messages);
        i18n.setLocaleMessage('fr', require('../i18n/fr').default.messages);
      });
    }
  }
};
