<template>
  <router-link :to="target" class="navbar-item" :class="itemClass">
    <span>{{ labelTr }}</span>
    <span v-if="badge" style="margin-left: 0.5rem;"
      ><span class="tag is-danger">{{ badge }}</span></span
    >
    <span v-if="dropdown" style="margin-left: 0.3rem;"
      ><span class="icon"><fa :icon="['fal', 'chevron-down']"/></span
    ></span>
    <span class="loader" v-if="isLoading"></span>
  </router-link>
</template>

<script>
import label from '../sqrd/mixins/label';
import tooltip from '../sqrd/mixins/tooltip';

export default {
  name: 'nav-bar-item',
  mixins: [label, tooltip],
  props: {
    label: { type: String, default: 'link' },
    badge: { type: [String, Number] },
    to: { type: Object },
    name: { type: String },
    tab: { type: Boolean },
    bid: { type: String },
    dropdown: Boolean,
    isLoading: Boolean
  },
  data() {
    return {};
  },
  computed: {
    itemClass() {
      return Object.assign(
        {
          'is-tab': this.tab
        },
        this.tooltipClass
      );
    },
    target() {
      if (this.name) {
        if (this.bid) {
          return { name: this.name, params: { bid: this.bid } };
        } else {
          return { name: this.name };
        }
      } else {
        return this.to;
      }
    }
  }
};
</script>
