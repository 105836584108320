import errorMessage from '../utils/errorMessage';

export default {
  namespaced: true,
  state: {
    notifications: []
  },
  getters: {
    notifications: state => state.notifications
  },
  mutations: {
    add(state, { label, message, error, color, ttl = 10, sticky = false }) {
      state.notifications.push({
        id: "" + Math.round(Math.random(1000000)),
        label,
        message,
        color,
        error,
        ttl,
        sticky
      });
    },
    tick(state, { expire = true }) {
      if (expire) {
        state.notifications.forEach(note => (note.ttl -= 1));
      }
      state.notifications = state.notifications.filter(
        note => note.sticky || note.ttl > 0
      );
    }
  },
  actions: {
    init: {
      root: true,
      handler(context) {
        setInterval(() => {
          context.dispatch('tick', { expire: true });
        }, 1000);
      }
    },
    tick(context) {
      if (context.state.notifications.length > 0) {
        context.commit('tick', { expire: true });
      }
    },
    notify: {
      root: true,
      handler(context, payload) {
        context.commit('add', payload);
      }
    },
    discard(context, { id }) {
      let notifications = context.state.notifications.filter(
        note => note.id !== id
      );
      context.commit('tick', { expire: false });
    },
    error: {
      root: true,
      handler(context, payload) {
        if (!payload.color) payload.color = 'danger';
        if (!payload.label) payload.label = errorMessage(payload.error);
        if (!payload.tll) payload.ttl = 10;
        context.commit('add', payload);
      }
    }
  }
};
