<template>
  <article class="message is-warning" v-if="error">
    <div class="message-header">
      <p>{{titleTr}}</p>
    </div>
    <div class="message-body" v-if="error.message">
      {{error.message}}
    </div>
  </article>
</template>

<script>
export default {
  name: 'SqrErrorNote',
  props: {
    error: { type: [Error, Object] },
    titleRaw: String
  },
  computed: {
    titleTr() {
      if (this.titleRaw) {
        return this.titleRaw;
      } else if (this.title) {
        return this.$t(this.title);
      } else {
        return this.$t('error_title');
      }
    }
  }
};
</script>
