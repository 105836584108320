import Vue from 'vue';
import * as firebase from 'firebase/app';

function getPromise() {
  let xResolve, xReject;
  const prom = new Promise((resolve, reject) => {
    xResolve = resolve;
    xReject = reject;
  });
  prom.resolve = xResolve.bind(prom);
  prom.reject = xReject.bind(prom);
  return prom;
}

let authenticatedPromise = getPromise();

export default {
  namespaced: true,
  state: {
    starting: false,
    loading: false,
    user: null,
    claims: {},
    email: '',
    errors: []
  },
  getters: {
    loading: state => state.loading,
    authenticated: state => !!state.user,
    user: state => state.user,
    uid: state => state.user && state.user.uid,
    claims: state => state.claims,
    email: state => state.email,
    displayName: state => (state.user && state.user.displayName) || null,
    photoURL: state => (state.user && state.user.photoURL) || null,
    errors: state => state.errors
  },
  mutations: {
    reset(state) {
      state.errors = [];
      state.loading = false;
    },
    starting(state) {
      state.starting = true;
    },
    started(state) {
      state.starting = false;
    },
    loading(state) {
      state.loading = true;
      state.errors = [];
    },
    loaded(state) {
      state.loading = false;
      state.errors = [];
    },
    userSet(state, { user }) {
      state.user = user;
      state.starting = false;
    },
    fieldSet(state, { field, value }) {
      Vue.set(state, field, value);
    },
    error(state, { error }) {
      state.errors.push(error);
      state.loading = false;
    }
  },

  // TODO https://github.com/EddyVerbruggen/nativescript-plugin-firebase/issues/1008#issuecomment-435635227
  // refresh on change detection

  actions: {
    init: {
      root: true,
      handler({ commit, dispatch }) {
        commit('starting');
        Vue.$fb()
          .auth()
          .onAuthStateChanged(async userRaw => {
            const user = JSON.parse(JSON.stringify(userRaw));
            if (user) {
              const idTokenResult = await Vue.$fb()
                .auth()
                .currentUser.getIdTokenResult();
              const claims = idTokenResult.claims;
              commit('fieldSet', { field: 'claims', value: claims });
              dispatch(
                'login',
                { uid: user.uid, user, claims },
                { root: true }
              );
            } else {
              dispatch('logout', {}, { root: true });
            }
            commit('started');
          });
      }
    },
    authenticated(context) {
      return authenticatedPromise;
    },
    reset(context) {
      context.commit('reset');
    },
    async createUserWithEmailAndPassword(context, { email, password }) {
      try {
        context.commit('loading');
        const result = await Vue.$fb()
          .auth()
          .createUserWithEmailAndPassword(email, password);
        context.commit('loaded');
        return result;
      } catch (error) {
        context.commit('error', { error });
        return Promise.reject(error);
      }
    },
    async signInWithEmailAndPassword(context, { email, password }) {
      try {
        context.commit('loading');
        const result = await Vue.$fb()
          .auth()
          .signInWithEmailAndPassword(email, password);
        context.commit('loaded');
        return result;
      } catch (error) {
        context.commit('error', { error });
        return Promise.reject(error);
      }
    },
    async signInWithGoogle(context) {
      try {
        context.commit('loading');
        const provider = new firebase.auth.GoogleAuthProvider();
        // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
        // firebase.auth().languageCode = 'pt';
        // provider.setCustomParameters({ 'login_hint': 'user@example.com' });
        Vue.$fb()
          .auth()
          .signInWithPopup(provider);
          // .signInWithRedirect(provider);
      } catch (error) {
        context.commit('error', { error });
      }
    },
    async signOut(context) {
      try {
        const result = await Vue.$fb()
          .auth()
          .signOut();
        context.commit('userSet', { user: null });
        return result;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    login: {
      root: true,
      handler(context, { user }) {
        if (authenticatedPromise.resolve) {
          authenticatedPromise.resolve(true);
        }
        authenticatedPromise = Promise.resolve(true);
        context.commit('userSet', { user });
      }
    },
    logout: {
      root: true,
      handler(context) {
        if (authenticatedPromise.resolve) {
          authenticatedPromise.resolve(false);
        }
        authenticatedPromise = Promise.resolve(false);
        context.commit('userSet', { user: null });
        context.commit('fieldSet', { field: 'claims', value: [] });
      }
    }
  }
};
