<template>
  <div class="navbar-item has-dropdown is-hoverable">
    <a class="navbar-link is-arrowless">
      <span v-if="broker">{{broker.name}}</span>
    </a>
    <div class="navbar-dropdown">
      <router-link
        class="navbar-item"
        :to="{name: 'broker', params: {bid: broker.id}}"
        v-for="broker in brokers"
        :key="broker.id">
        {{broker.name}}
      </router-link>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'NavBrokerPicker',
  computed: {
    ...mapGetters('brokers', ['brokers', 'broker'])
  }
};
</script>
