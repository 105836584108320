/* eslint-disable no-console */
import { register } from 'register-service-worker'

/* eslint-disable no-unused-vars */
let resolver;
window.swUpdated = new Promise(function (resolve, reject) {
  resolver = resolve;
});

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.'
        // For more details, visit https://goo.gl/AFskqB
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.');
      if (window.Vue && window.Vue.$store) window.Vue.$store.commit('serviceWorker/status', {status: 'updateFound'});
    },
    updated () {
      console.log('New content is available; please refresh.')
      if (window.Vue && window.Vue.$store) window.Vue.$store.commit('serviceWorker/status', {status: 'updated'});
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
      // window.Vue.$store.commit('serviceWorker/status', {status: 'offline'});
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
