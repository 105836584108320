import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    updating: {},
    updateError: {}
  },
  mutations: {
    updating(state, { path }) {
      Vue.set(state.updating, path, true);
      Vue.delete(state.updateError, path);
    },
    error(state, { path, error }) {
      Vue.set(state.updateError, path, error);
    },
    finished(state, { path }) {
      Vue.delete(state.updating, path);
    }
  },
  actions: {
    async update({ commit }, { path, doc }) {
      try {
        commit('updating', { path });
        const res = await Vue.$db()
          .doc(path)
          .update(doc);
        return res;
      } catch (error) {
        commit('error', { path, error });
        return Promise.reject(error);
      } finally {
        commit('finished', { path });
      }
    }
  }
};
