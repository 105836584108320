import Vue from 'vue';
import { DateTime } from 'luxon';
import buildRef from '@/utils/buildRef';

// function buildRef(path) {
//   let query = Vue.$db();
//   let col = true;
//   path.forEach((item, idx) => {
//     query = col ? query.collection(item) : query.doc(item);
//     col = !col;
//   });
//   return query;
// }

export default function(collection) {
  let unsubscribe = null;
  return {
    namespaced: true,
    state: {
      path: [],
      id: null,
      record: null,
      updates: {},
      loading: false,
      loadError: null,
      saving: false,
      saveError: null
    },
    getters: {
      id: state => state.id,
      record: state => state.record,
      updates: state => state.updates,

      loading: state => state.loading,
      loadError: state => state.loadError,

      sending: state => state.sending,
      sendError: state => state.sendError,

      saving: state => state.saving,
      saveError: state => state.saveError,

      error: state => state.loadError || state.sendError || state.saveError
    },
    mutations: {
      init(state, { id, path, record }) {
        state.path = path || [];
        state.id = id;
        state.record = record;
        state.loadError = null;
        state.loading = false;
      },
      loading(state, { path, id }) {
        state.path = path || [];
        state.id = id;
        state.loadError = null;
        state.loading = true;
      },
      loaded(state, { path, id, doc }) {
        state.path = path || [];
        state.id = id;
        state.record = doc;
        state.updates = {};
        state.loadError = null;
        state.loading = false;
      },
      fieldSet(state, { group, field, value }) {
        if (!state.record) return;
        if (group) {
          if (!state.record[group]) {
            Vue.set(state.record, group, {});
          }
          Vue.set(state.record[group], field, value);
          Vue.set(state.updates, [group, field].join('.'), value);
        } else {
          Vue.set(state.record, field, value);
          Vue.set(state.updates, field, value);
        }
      },
      loadError(state, { error }) {
        state.loading = false;
        state.loadError = { error };
      },
      sending(state) {
        state.sending = true;
        state.sendError = null;
      },
      sent(state) {
        state.sending = false;
        state.updates = {};
      },
      sendError(state, { error }) {
        state.sendError = error;
      },
      saving(state) {
        state.saving = true;
        state.saveError = null;
      },
      saved(state, { id }) {
        state.id = id;
        state.saveError = null;
        state.saving = false;
      },
      saveError(state, { error }) {
        state.saveError = error;
        state.saving = false;
      }
    },
    actions: {
      init(context, { path, id, record }) {
        context.commit('init', { path, id, record });
      },
      sub(context, { path, id }) {
        // console.log('sub', path, id);
        if (!id) return;
        context.commit('loading', { path, id });

        return new Promise((resolve, reject) => {
          const ref = buildRef(path, collection, id);
          unsubscribe = ref.onSnapshot(
            docSnapshot => {
              const doc = docSnapshot.data();
              context.commit('loaded', { path, id, doc });
              resolve(doc);
            },
            error => {
              context.commit('loadError', { error });
              reject(error);
            }
          );
        });
      },
      fieldSet({dispatch}, { field, value }) {
        return dispatch('update', { [field]: value });
      },
      async save({state, commit, rootGetters}) {
        try {
          commit('saving');
          const record = {
            ...state.record,
            updated: DateTime.utc().toISO(),
            updatedBy: rootGetters['auth/uid'],
            updatedByName: rootGetters['auth/displayName']
          };
          let id = state.id;
          if (!id) {
            const ref = buildRef(state.path, collection);
            const res = await ref.add(record);
            id = res.id;
            // console.log(`saved with id ${id}`);
            // NOTE should we subscribe to futur changes?
          } else {
            const ref = buildRef(state.path, collection, state.id);
            await ref.set(record);
          }
          commit('saved', { id });
          return { id };
        } catch (error) {
          commit('saveError', { error });
          return Promise.reject(error);
        }
      },
      async update({state, rootGetters, commit}, payload) {
        try {
          const id = state.id;
          if (!id) return;
          const fullPayload = {
            ...payload,
            updated: DateTime.utc().toISO(),
            updatedBy: rootGetters['auth/uid'],
            updatedByName: rootGetters['auth/displayName']
          };
          const ref = buildRef(state.path, collection, state.id);
          await ref.update(fullPayload);
        } catch (error) {
          console.log(error);
          commit('saveError', { error });
          return Promise.reject(error);
        }
      },
      async send({ state, commit, rootGetters }) {
        try {
          commit('sending');
          const path = [...state.path, collection, state.id];
          if (Object.keys(state.updates).length === 0) {
            return;
          }
          const ref = buildRef(path);
          const updates = {
            ...state.updates,
            updated: DateTime.utc().toISO(),
            updatedBy: rootGetters['auth/uid'],
            updatedByName: rootGetters['auth/displayName']
          };
          const res = await ref.update(updates);
          commit('sent');
          return res;
        } catch (error) {
          console.log(error);
          commit('sendError', { error });
        }
      },
      unsub() {
        // context
        if (unsubscribe) {
          unsubscribe();
        }
      }
      // login: {
      //   root: true,
      //   handler(context) {}
      // },
      // logout: {
      //   root: true,
      //   handler(context) {}
      // }
    }
  };
}
