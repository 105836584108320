import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import { times } from 'ramda';
// import Start from './views/Start.vue'
// import Login from './views/Login.vue'
// import Logout from './views/Logout.vue'

Vue.use(Router);

// prettier-ignore
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  // linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-active is-active-exact',
  routes: [
    { name: 'launch', path: '/', meta: {public: true}, component: Home},
    { name: 'brokers', path: '/b', props: true, component: () => import(/* webpackChunkName: "brokers" */ './views/Brokers.vue')},

    { name: 'broker', path: '/b/:bid', props: true, component: () => import(/* webpackChunkName: "broker" */ './views/Broker.vue')},
    { name: 'relations', path: '/b/:bid/relations', props: true, component: () => import(/* webpackChunkName: "relations" */ './views/Relations.vue')},
    { name: 'birthdays', path: '/b/:bid/birthdays', props: true, component: () => import(/* webpackChunkName: "birthdays" */ './views/Birthdays.vue')},
    { name: 'relations-add', path: '/b/:bid/relations/add', props: true, component: () => import(/* webpackChunkName: "RelationsAdd" */ './views/RelationsAdd.vue')},

    { name: 'relation', path: '/b/:bid/relations/:rid', redirect: to => ({name: 'relation-general', params: to.params}), props: true, component: () => import(/* webpackChunkName: "Relation" */ './views/Relation.vue'), children: [
      { name: 'relation-general', path: '', props: true, component: () => import(/* webpackChunkName: "RelationGeneral" */ './views/RelationGeneral.vue')},
      { name: 'relation-policies', path: 'policies', props: true, component: () => import(/* webpackChunkName: "RelationPolicies" */ './views/RelationPolicies.vue')},
      { name: 'relation-policy-add', path: 'policies/add', props: true, component: () => import(/* webpackChunkName: "RelationPolicyAdd" */ './views/RelationPolicyAdd.vue')},
      { name: 'relation-policy', path: 'policies/:pid', props: true, component: () => import(/* webpackChunkName: "RelationPolicy" */ './views/RelationPolicy.vue')},
      { name: 'relation-documents', path: 'documents', props: true, component: () => import(/* webpackChunkName: "RelationPolicies" */ './views/RelationDocuments.vue')},
    ]},

    { name: 'relation-name', path: '/b/:bid/relations/:rid/name', props: true, component: () => import(/* webpackChunkName: "RelationName" */ './views/RelationName.vue')},
    { name: 'relation-management', path: '/b/:bid/relations/:rid/management', props: true, component: () => import(/* webpackChunkName: "RelationManagement" */ './views/RelationManagement.vue')},
    { name: 'relation-address', path: '/b/:bid/relations/:rid/address', props: true, component: () => import(/* webpackChunkName: "RelationAddress" */ './views/RelationAddress.vue')},
    { name: 'relation-coordinates', path: '/b/:bid/relations/:rid/coordinates', props: true, component: () => import(/* webpackChunkName: "RelationCoordinates" */ './views/RelationCoordinates.vue')},
    { name: 'relation-notes', path: '/b/:bid/relations/:rid/notes', props: true, component: () => import(/* webpackChunkName: "RelationNotes" */ './views/RelationNotes.vue')},
    { name: 'relation-bank-account', path: '/b/:bid/relations/:rid/bank-account', props: true, component: () => import(/* webpackChunkName: "RelationBankAccount" */ './views/RelationBankAccount.vue')},
    { name: 'relation-driver', path: '/b/:bid/relations/:rid/driver', props: true, component: () => import(/* webpackChunkName: "relation-driver" */ './views/RelationDriver.vue')},

    { name: 'relation-prospection', path: '/b/:bid/relations/:rid/prospection', props: true, component: () => import(/* webpackChunkName: "RelationProspection" */ './views/RelationProspection.vue')},
    { name: 'relation-offers', path: '/b/:bid/relations/:rid/offers', props: true, component: () => import(/* webpackChunkName: "RelationOffers" */ './views/RelationOffers.vue')},
    { name: 'relation-offer-vehicule-add', path: '/b/:bid/relations/:rid/offers/vehicule/add', props: true, component: () => import(/* webpackChunkName: "RelationOfferVehiculeAdd" */ './views/RelationOfferVehiculeAdd.vue')},
    { name: 'relation-offer-vehicule', path: '/b/:bid/relations/:rid/offers/vehicule/:id', props: true, component: () => import(/* webpackChunkName: "RelationOfferVehicule" */ './views/RelationOfferVehicule.vue')},

    { name: 'relation-emails', path: '/b/:bid/relations/:rid/emails', props: true, component: () => import(/* webpackChunkName: "RelationEmails" */ './views/RelationEmails.vue')},
    { name: 'relation-email', path: '/b/:bid/relations/:rid/emails/new', props: true, component: () => import(/* webpackChunkName: "RelationEmailNew" */ './views/RelationEmailNew.vue')},

    { name: 'policies', path: '/b/:bid/policies', props: true, component: () => import(/* webpackChunkName: "Policies" */ './views/Policies.vue')},

    { name: 'search', path: '/b/:bid/search', redirect: to => ({name: 'search-relations', params: to.params}), props: true, component: () => import(/* webpackChunkName: "Search" */ './views/Search.vue'), children: [
      { name: 'search-relations', path: 'relations', props: true, component: () => import(/* webpackChunkName: "SearchRelations" */ './views/SearchRelations.vue')},
      { name: 'search-policies', path: 'policies', props: true, component: () => import(/* webpackChunkName: "SearchPolicies" */ './views/SearchPolicies.vue')},
    ]},
    { name: 'search-reindex', path: '/b/:bid/search/index', props: true, component: () => import(/* webpackChunkName: "SearchIndex" */ './views/SearchIndex.vue')},

    { name: 'insurers', path: '/b/:bid/insurers', props: true, component: () => import(/* webpackChunkName: "insurers" */ './views/Insurers.vue')},
    { name: 'branches', path: '/b/:bid/branches', props: true, component: () => import(/* webpackChunkName: "branches" */ './views/Branches.vue')},

    { name: 'inquiries', path: '/b/:bid/inquiries', props: true, component: () => import(/* webpackChunkName: "Inquiries"*/ '@/views/inquiries/Inquiries')},
    { name: 'inquiry-start', path: '/b/:bid/inquiries/start', props: true, component: () => import(/* webpackChunkName: "InquiryStart"*/ '@/views/inquiries/InquiryStart')},
    { name: 'inquiry', path: '/b/:bid/inquiries/:iid', redirect: to => ({name: 'inquiry-fill', params: to.params}), props: true, component: () => import(/* webpackChunkName: "Inquiry"*/ '@/views/inquiries/Inquiry'),
      children: [
        { name: 'inquiry-fill', path: 'fill', props: true, component: () => import(/* webpackChunkName: "InquiryFill"*/ '@/views/inquiries/InquiryFill')},
        { name: 'inquiry-close', path: 'close', props: true, component: () => import(/* webpackChunkName: "InquiryClose"*/ '@/views/inquiries/InquiryClose')},
      ]
    },
    { name: 'campaigns', path: '/b/:bid/campaigns', props: true, component: () => import(/* webpackChunkName: "Campaigns"*/ '@/views/campaigns/Campaigns')},
    { name: 'campaign', path: '/b/:bid/campaigns/:cid', redirect: to => ({name: 'campaign-recipients', params: to.params}), props: true, component: () => import(/* webpackChunkName: "Campaign"*/ '@/views/campaigns/Campaign'),
      children: [
        { name: 'campaign-recipients', path: 'recipients', props: true, component: () => import(/* webpackChunkName: "CampaignRecipients"*/ '@/views/campaigns/CampaignRecipients')},
        { name: 'campaign-email', path: 'email', props: true, component: () => import(/* webpackChunkName: "CampaignEmail"*/ '@/views/campaigns/CampaignEmail')},
      ]
    },

    { name: 'inquiry-models', path: '/b/:bid/settings/inquiry-models', props: true, component: () => import(/* webpackChunkName: "InquiryModels"*/ '@/views/inquiries/InquiryModels')},
    { path: '/b/:bid/settings/inquiry-models/:mid', props: true, component: () => import(/* webpackChunkName: "InquiryModel"*/ '@/views/inquiries/InquiryModel'),
      children: [
        { name: 'inquiry-model-questions', path: '', props: true, component: () => import(/* webpackChunkName: "InquiryModelQuestions"*/ '@/views/inquiries/InquiryModelQuestions')},
        { name: 'inquiry-model-recipients', path: 'recipients', props: true, component: () => import(/* webpackChunkName: "InquiryModelRecipients"*/ '@/views/inquiries/InquiryModelRecipients')},
        { name: 'inquiry-model-email', path: 'email', props: true, component: () => import(/* webpackChunkName: "InquiryModelEmail"*/ '@/views/inquiries/InquiryModelEmail')},
        { name: 'inquiry-model-email-close', path: 'email-close', props: true, component: () => import(/* webpackChunkName: "InquiryModelEmailClose"*/ '@/views/inquiries/InquiryModelEmailClose')},
      ]
    },

    { name: 'mailroom', path: '/b/:bid/mailroom', props: true, component: () => import(/* webpackChunkName: "Mailroom"*/ '@/views/mailroom/Mailroom')},

    { name: 'documents', path: '/b/:bid/documents/:folder(.*)', props: true, component: () => import(/* webpackChunkName: "Documents"*/ '@/views/Documents')},

    { name: 'settings', path: '/b/:bid/settings', props: true, component: () => import(/* webpackChunkName: "Settings"*/ '@/views/settings/Settings')},
    { name: 'settings-email', path: '/b/:bid/settings/email', props: true, component: () => import(/* webpackChunkName: "settings-email" */ './views/settings/SettingsEmail.vue')},
    { name: 'emails-templates', path: '/b/:bid/emails/templates', props: true, component: () => import(/* webpackChunkName: "emails-templates" */ './views/settings/EmailsTemplates.vue')},
    { name: 'emails-templates-add', path: '/b/:bid/emails/templates/add', props: true, component: () => import(/* webpackChunkName: "emails-template-add" */ './views/settings/EmailsTemplate.vue')},
    { name: 'emails-template', path: '/b/:bid/emails/templates/:id', props: true, component: () => import(/* webpackChunkName: "emails-template" */ './views/settings/EmailsTemplate.vue')},

    { name: 'subscription', path: '/subscription', component: () => import(/* webpackChunkName: "WIP"*/ '@/views/WIP')},

    { name: 'user-menu', path: '/user', component: () => import(/* webpackChunkName: "User" */ './views/user/UserMenu.vue')},
    { name: 'profile', path: '/user/profile', component: () => import(/* webpackChunkName: "Profile" */ './views/user/Profile.vue')},

    { name: 'password-change', path: '/password/change', component: () => import(/* webpackChunkName: "PasswordChange" */ './views/auth/PasswordChange.vue')},
    { name: 'password-lost', path: '/password/lost', meta: {public: true}, component: () => import(/* webpackChunkName: "PasswordLost" */ './views/auth/PasswordLost.vue')},

    { name: 'sign-up', path: '/sign-up', meta: {public: true}, component: () => import(/* webpackChunkName: "SignUp" */ './views/auth/SignUp.vue')},

    { name: 'auth', path: '/auth', meta: {public: true},  component: () => import(/* webpackChunkName: "Auth" */ '@/views/Auth'), meta: {public: true}},

    { name: 'forbidden', path: '/forbidden', component: () => import(/* webpackChunkName: "Forbidden" */ './views/auth/Forbidden.vue')},
    { name: 'permissions', path: '/permissions', component: () => import(/* webpackChunkName: "Permissions" */ './views/auth/Permissions.vue')},
    { name: 'sign-in', path: '/sign-in', meta: {public: true}, component: () => import(/* webpackChunkName: "SignIn" */ './views/auth/SignIn.vue')},
    { name: 'sign-out', path: '/sign-out', meta: {public: true}, component: () => import(/* webpackChunkName: "SignOut" */ './views/auth/SignOut.vue')},

    { path: '*', redirect: {name: 'home'}}
  ],
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  }
})

router.beforeEach(async (to, from, next) => {
  const allPublic = to.matched.every(route => Boolean(route.meta.public));
  if (allPublic || (await Vue.$store.dispatch('auth/authenticated', {}))) {
    next();
  } else {
    next({ name: 'sign-in' });
  }
});

export default router;
