<template>
  <div class="h-screen flex overflow-hidden bg-gray-50">
    <!-- Off-canvas menu for mobile -->
    <div class="md:hidden" v-show="menu">
      <div class="fixed inset-0 flex z-40">
        <!--
        Off-canvas menu overlay, show/hide based on off-canvas menu state.

        Entering: "transition-opacity ease-linear duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "transition-opacity ease-linear duration-300"
          From: "opacity-100"
          To: "opacity-0"
      -->
        <div class="fixed inset-0">
          <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
        </div>
        <!--
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      -->
        <div
          class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800"
        >
          <div class="absolute top-0 right-0 -mr-14 p-1">
            <button
              class="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
              aria-label="Close sidebar"
              @click="menu = false"
            >
              <fa
                class="h-6 w-6 text-white"
                :icon="['far', 'times']"
                size="lg"
              />
            </button>
          </div>
          <div class="flex-shrink-0 flex items-center px-4">
            <!-- <img class="h-8 w-auto" src="/assets/logo.svg" alt="Workflow" /> -->
            <!-- <logo class="h-8 w-8" /> -->
            <div class="font-bold text-white text-2xl">BRKR</div>
          </div>
          <div class="mt-5 flex-1 h-0 overflow-y-auto">

            <nav class="px-2 nav-mobile">
              <router-link class="nav-link" :to="{name: 'brokers'}" exact>
                <fa class="nav-icon" :icon="['far', 'home']" size="lg" fixed-width />
                Brokers
              </router-link>

              <div v-if="bid">
                <div class="mt-2 border-t border-gray-600 text-gray-400 text-sm font-medium pt-1 -mx-2 px-4">
                  {{broker && broker.name || 'Broker'}}
                </div>
                <router-link class="nav-link" :to="{name: 'broker', params: {bid}}" exact>
                  <fa class="nav-icon" :icon="['far', 'sitemap']" size="lg" fixed-width />
                  Tableau de bord
                </router-link>
              </div>

              <!-- <div v-if="rid">
                <div class="mt-2 border-t border-gray-600 text-gray-400 text-sm font-medium pt-1 -mx-2 px-4">
                  {{relation && relation.name || 'Relation' }}
                </div>
              </div> -->

              <router-link
                v-for="item in items"
                :key="item.id"
                class="nav-link"
                :to="item.to"
                :exact="item.exact"
              >
                <fa class="nav-icon" :icon="['far', item.icon]" size="lg" fixed-width />
                {{ item.label }}
              </router-link>

              <div class="mt-2 border-t border-gray-600 ">
                <router-link class="nav-link" :to="{name: 'search', params: { bid }}">
                  <fa class="nav-icon" :icon="['far', 'search']" size="lg" fixed-width />
                  Rechercher
                </router-link>
                <router-link class="nav-link" :to="{name: 'settings', params: {bid}}">
                  <fa class="nav-icon" :icon="['far', 'cogs']" size="lg" fixed-width />
                  Paramètres
                </router-link>
              </div>
            </nav>
          </div>
        </div>
        <div class="flex-shrink-0 w-14">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </div>
    </div>
    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:flex-shrink-0">
      <div class="flex flex-col w-64 bg-gray-800 pt-5 pb-0">
        <div class="flex items-center flex-shrink-0 px-4">
          <!-- <logo class="h-8 w-8" /> -->
          <div class="font-bold text-white text-2xl">
            BRKR
          </div>
        </div>

        <div class="mt-5 h-0 flex-1 flex flex-col overflow-y-auto">
          <!-- Sidebar component, swap this element with another sidebar if you like -->
          <nav class="flex-1 px-2 bg-gray-800">
            <router-link class="nav-link" :to="{name: 'brokers'}" exact>
              <fa class="nav-icon" :icon="['far', 'home']" size="lg" fixed-width />
              Brokers
            </router-link>

            <div v-if="bid">
              <div class="mt-2 border-t border-gray-600 text-gray-400 text-sm font-medium pt-1 -mx-2 px-4">
                {{broker && broker.name || 'Broker'}}
              </div>
              <router-link class="nav-link" :to="{name: 'broker', params: {bid}}" exact>
                <fa class="nav-icon" :icon="['far', 'sitemap']" size="lg" fixed-width />
                Tableau de bord
              </router-link>
            </div>

            <!-- <div v-if="rid">
              <div class="mt-2 border-t border-gray-600 text-gray-400 text-sm font-medium pt-1 -mx-2 px-4">
                {{relation && relation.name || 'Relation' }}
              </div>
            </div> -->

            <router-link
              v-for="item in items"
              :key="item.id"
              class="nav-link"
              :to="item.to"
              :exact="item.exact"
            >
              <fa class="nav-icon" :icon="['far', item.icon]" size="lg" fixed-width />
              {{ item.label }}
            </router-link>
          </nav>
        </div>

        <div v-if="bid" class="border-t border-gray-600 py-1">
          <nav class="px-2">
          <router-link class="nav-link" :to="{name: 'search', params: { bid }}">
            <fa class="nav-icon" :icon="['far', 'search']" size="lg" fixed-width />
            Rechercher
          </router-link>
          <router-link class="nav-link" :to="{name: 'settings', params: { bid }}">
            <fa class="nav-icon" :icon="['far', 'cogs']" size="lg" fixed-width />
            Paramètres
          </router-link>
          </nav>
        </div>

        <div class="flex-shrink-0 flex bg-gray-700 p-4" v-if="authenticated">
          <router-link :to="{name: 'user-menu'}" class="flex-shrink-0 w-full group block">
            <div class="flex items-center">
              <div v-if="user && user.photoURL">
                <img
                  class="inline-block h-9 w-9 rounded-full"
                  :src="user.photoURL"
                  :alt="displayName"
                />
              </div>
              <div class="ml-3">
                <p class="text-sm leading-5 font-medium text-white">
                  {{ displayName }}
                </p>
                <p
                  class="text-xs leading-4 font-medium text-gray-300 group-hover:text-gray-200 transition ease-in-out duration-150"
                >
                  Menu utilisateur
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-white">
        <button
          class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150"
          aria-label="Open sidebar"
          @click="menu = !menu"
        >
          <fa class="w-6 h-6" :icon="['far', 'bars']" size="lg" />
        </button>
      </div>
      <main
        class="flex-1 relative z-0 overflow-y-auto focus:outline-none"
        tabindex="0"
      >
        <slot>
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 class="text-2xl font-semibold text-gray-900">Loading…</h1>
          </div>
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <!-- Replace with your content -->
            <div class="py-4">
              <div
                class="border-4 border-dashed border-gray-200 rounded-lg h-96"
              ></div>
            </div>
            <!-- /End replace -->
          </div>
        </slot>
      </main>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.nav-link {
  @apply mt-1 flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-300 rounded-md transition ease-in-out duration-150;
}
.nav-mobile .nav-link {
  @apply text-base leading-6;
}
.nav-link:hover {
  @apply text-white bg-gray-700;
}
.nav-link:focus {
  @apply outline-none text-white bg-gray-600;
}
.nav-icon {
  @apply mr-3 h-6 w-6 text-gray-400 transition ease-in-out duration-150
}
.nav-link:hover .nav-icon {
  @apply text-gray-200;
}
.router-link-active,
.is-active {
  @apply bg-gray-900;
  /* @apply text-indigo-300 rounded-md hover:text-white hover:bg-indigo-700 focus:outline-none focus:text-white focus:bg-indigo-700 transition ease-in-out duration-150" */
}
</style>

<script>
import Logo from '@/components/Logo';
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'Shell',
  components: { Logo },
  data() {
    return {
      menu: false
    };
  },
  computed: {
    items() {
      const bid = this.$route.params.bid;
      // const rid = this.$route.params.rid;
      // if (rid && bid) {
      //   return [
      //     {
      //       id: 'relation',
      //       icon: 'user',
      //       label: 'Relation',
      //       to: { name: 'relation-general', params: { bid, rid } },
      //       exact: true
      //     },
      //     {
      //       id: 'relation',
      //       icon: 'file-contract',
      //       label: 'Polices',
      //       to: { name: 'relation-policies', params: { bid, rid } }
      //     },
      //     {
      //       id: 'documents',
      //       icon: 'file',
      //       label: 'Documents',
      //       to: { name: 'relation-documents', params: { bid, rid } }
      //     },
      //     {
      //       id: 'inquiries',
      //       icon: 'seedling',
      //       label: "Demandes d'offres",
      //       to: { name: 'relation-offers', params: { bid, rid } }
      //     }
      //   ];
      // } else
      if (bid) {
        return [
          {
            id: 'users',
            icon: 'users',
            label: 'Relations',
            to: { name: 'relations', params: { bid } }
          },
          {
            id: 'policies',
            icon: 'file-contract',
            label: 'Polices',
            to: { name: 'policies', params: { bid } }
          },
          {
            id: 'campaigns',
            icon: 'bullhorn',
            label: 'Campagnes',
            to: { name: 'campaigns', params: { bid } }
          },
          {
            id: 'inquiries',
            icon: 'seedling',
            label: "Demandes d'offres",
            to: { name: 'inquiries', params: { bid } }
          },
          // {
          //   id: 'inbox',
          //   icon: 'inbox',
          //   label: 'Courrier à classer',
          //   to: { name: 'mailroom', params: { bid } }
          // },
        ];
      } else {
        return [];
      }
    },
    bid () {
      return this.$route?.params?.bid;
    },
    ...mapGetters('brokers', ['broker']),

    rid () {
      return this.$route?.params?.rid;
    },
    ...mapGetters('relation', {relation: 'record'}),

    ...mapGetters('auth', ['displayName', 'authenticated']),
    ...mapState('auth', ['user']),
  }
};
</script>
