export default {
  namespaced: true,
  state: {
    status: '',
  },
  getters: {
    status: state => state.status
  },
  mutations: {
    status (state, {status}) {
      state.status = status;
    }
  },
  actions: {

  }
};
