import Vue from 'vue';
import {equals} from 'ramda';


export default function({path, where, orderBy, pageSize, pageSizes}) {
  let unsubscribe = null;
  let pagesSnapshots = [];
  return {
    namespaced: true,
    state: {
      loading: true,

      page: 0,
      totalPages: undefined,

      path: path || [],
      where: where || [],
      orderBy: orderBy || [],

      pageSize: pageSize || 10,
      pageSizes: pageSizes || [10, 25, 50, 100],

      records: [],
      loadError: null,

      removingById: {},
      removeErrorById: {}
    },
    getters: {
      records: state => state.records,

      loading: state => state.loading,
      loadError: state => state.loadError,

      removing: state => state.removing,

      error: state => state.loadError,

      pagination: state => ({
        page: state.page,
        pageSize: state.pageSize,
        pageSizes: state.pageSizes,
        totalPages: state.totalPages
      })
    },
    mutations: {
      loading(state) {
        state.loading = true;
        state.loadError = null;
      },
      loaded (state) {
        state.loadError = null;
        state.loading = false;
      },
      reset (state, {records}) {
        state.records = records;
      },
      fieldSet (state, {field, value}) {
        Vue.set(state, field, value);
      },
      recordChange(state, { type, id, doc, newIndex, oldIndex }) {
        state.loading = false;
        doc = { ...doc, id };
        if (type === 'added') {
          state.records.splice(newIndex, 0, doc);
          // if we want to handle references we would do it here
        } else if (type === 'modified') {
          // remove the old one first
          state.records.splice(oldIndex, 1);
          // if we want to handle references we would have to unsubscribe
          // from old references' listeners and subscribe to the new ones
          state.records.splice(newIndex, 0, doc);
        } else if (type === 'removed') {
          state.records.splice(oldIndex, 1);
          // if we want to handle references we need to unsubscribe
          // from old references
        }
      },
      removing(state, { id }) {
        state.removing[id] = true;
      },
      removed(state, { id }) {
        delete state.removing[id];
      },
      removeError(state, { id }) { // { …, error }
        delete state.removing[id];
      },
      loadError(state, { error }) {
        state.loading = false;
        state.loadError = error;
      },
      logout(state) {
        state.records = [];
        state.loading = false;
        state.removing = {};
        state.error = null;
      }
    },
    actions: {
      async pageSet (context, {page}) {
        return context.dispatch('sub', {page});
      },
      async pageSize (context, {pageSize}) {
        if (pageSize !== context.state.pageSize) {
          context.commit('fieldSet', {field: 'pageSize', value: pageSize});
          return context.dispatch('sub', {page: 0, reload: true});
        }
      },
      async orderBy (context, {orderBy}) {
        if (!equals(orderBy, context.state.orderBy)) {
          context.commit('fieldSet', {field: 'orderBy', value: orderBy});
          return context.dispatch('sub', {reload: true});
        }
      },
      async where (context, {where}) {
        if (!equals(where, context.state.where)) {
          context.commit('fieldSet', {field: 'where', value: where});
          return context.dispatch('sub', {reload: true});
        }
      },
      async sub(context, { path, where, orderBy, reload, page }) {

        if (path && !equals(path, context.state.path)) {
          context.commit('fieldSet', {field: 'path', value: path});
          reload = true;
          page = undefined;
        }

        if (where && !equals(where, context.state.where)) {
          context.commit('fieldSet', {field: 'where', value: where});
          console.log('we need to reload');
          reload = true;
          page = undefined;
        }

        if (orderBy && !equals(orderBy, context.state.orderBy)) {
          context.commit('fieldSet', {field: 'orderBy', value: orderBy});
          reload = true;
          page = undefined;
        }


        if (!reload && page == context.state.page && unsubscribe) {
          return;
        } else if (page < 0) {
          return;
        } else if (context.state.totalPages && page >= context.state.totalPages) {
          return;
        }

        if (unsubscribe) {
          unsubscribe();
        }

        if (reload || ! page) {
          page = 0;
          pagesSnapshots = [];
        }

        context.commit('loading');

        try {
          const col = context.state.path.reduce((acc, item, index) => index % 2 ? acc.doc(item) : acc.collection(item), Vue.$db());
          const where = context.state.where.reduce((query, w) => query.where(...w), col)
          const sorted = context.state.orderBy.reduce((query, o) => query.orderBy(...o), where);
          const limitted = sorted.limit(context.state.pageSize);
          let paged = limitted;

          if (pagesSnapshots.length === 0) {
            // console.log('first page', page, pagesSnapshots.length);
            // do nothing, this is the first page
            paged = limitted;
            page = 0;
          } else if (page === pagesSnapshots.length) {
            // console.log('new page', page, pagesSnapshots.length);
            // this page has not been loaded yet
            const pageSnapshot = pagesSnapshots[page - 1];
            const docs = pageSnapshot.docs;
            const docLast = docs[docs.length - 1];
            paged = paged.startAfter(docLast);
          } else {
            // console.log('previously loaded page', page, pagesSnapshots.length);
            // this page was loaded before
            const docFirst = pagesSnapshots[page].docs[0];
            paged = paged.startAt(docFirst);
          }

          context.commit('reset', {records: []});
          context.commit('fieldSet', {field: 'page', value: page});

          unsubscribe = paged.onSnapshot(ref => {
            pagesSnapshots[page] = ref;

            if (ref.empty) {
              // no record, we have one less page
              // console.log('result is empty', page);
              context.commit('fieldSet', {field: 'totalPages', value: page || 1});
              context.commit('reset', {records: []});
              context.commit('loaded');
              return;
            } else if (!context.state.totalPages && ref.docs.length < context.state.pageSize) {
              // we reached last page
              context.commit('fieldSet', {field: 'totalPages', value: page + 1});
            }

            ref.docChanges().forEach(change => {
              context.commit('recordChange', {
                type: change.type,
                id: change.doc.id,
                doc: change.doc.data(),
                newIndex: change.newIndex,
                oldIndex: change.oldIndex
              });
            });
          }, error => context.commit('loadError', {error}));
        } catch (error) {
          context.commit('loadError', { error });
          return Promise.reject(error);
        }
      },
      async unsub() { // context, payload
        if (!unsubscribe) return;
        unsubscribe();
        unsubscribe = null;
      },
      async remove(context, { id }) {
        try {
          return Vue.$db()
            .collection(context.state.collection)
            .doc(id)
            .delete();
        } catch (error) {
          // console.log(error);
          context.commit('removeError', { id, error });
          context.dispatch('removeError', { error }, { root: true });
        }
      }
    }
  };
}
