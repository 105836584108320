import Vue from 'vue';
import { props, fromPairs, toPairs, zipObj } from 'ramda';
import Fuse from 'fuse.js';
import buildRef from '@/utils/buildRef';

export default function({
  id,
  threshold,
  distance
}) {
  let fuse = null;
  const indexId = id;
  const propsFn = props;
  return {
    namespaced: true,
    state: {
      path: null,
      query: '',
      archivedIncluded: false,
      index: null,
      results: []
    },
    getters: {},
    mutations: {
      loading(state) {
        state.loading = true;
      },
      loaded(state, { index }) {
        state.loaded = false;
        state.index = Object.freeze(index);
        const keys = [...index.props.filter(val => val !== 'archived')];
        const options = {
          shouldSort: true,
          tokenize: true,
          threshold: threshold || 0.2,
          location: 0,
          distance: typeof distance === 'undefined' ? 200 : distance,
          maxPatternLength: 32,
          minMatchCharLength: 3,
          matchAllTokens: true,
          keys
        };

        const propsWithId = ['id', ...index.props];
        const list = toPairs(index.entries).map(([id, values]) =>
          zipObj(propsWithId, [id, ...values])
        );

        fuse = new Fuse(list, options);
      },
      searched(state, { query, archivedIncluded, results }) {
        state.query = query;
        state.results = results;
        state.archivedIncluded = archivedIncluded;
      }
    },
    actions: {
      load({ state, commit }, { bid }) {
        if (state.index) {
          return state.index;
        }
        return new Promise((resolve, reject) => {
          const ref = buildRef('brokers', bid, 'indexes', id);
          ref.onSnapshot(snap => {
            const index = snap.data();
            resolve(index);
            commit('loaded', { index });
          });
        });
      },
      search({ state, commit }, { query, archivedIncluded }) {
        if (!fuse) return;
        if (!query) {
          commit('searched', { query, results: [] });
        } else if (
          query === state.query &&
          state.archivedIncluded === archivedIncluded
        ) {
          console.log(`identical query ${query}`);
        } else {
          const results2 = fuse.search(query);
          const results = archivedIncluded
            ? results2
            : results2.filter(r => !r.archived);
          commit('searched', { query, archivedIncluded, results });
        }
      },
      async reindex({ state, dispatch }, { bid, id, doc }) {
        try {
          if (!state.index) {
            await dispatch('load', { bid });
          }
          const props = state.index.props;
          const key = `entries.${id}`;
          const arr = propsFn(props, doc).map(v =>
            typeof v === 'undefined' ? '' : v
          );
          const ref = buildRef(['brokers', bid, 'indexes', indexId]);
          return ref.update({ [key]: arr });
        } catch (error) {
          return Promise.reject(error);
        }
      },
      async rebuild ({commit}, {bid, type, props, docs}) {
        try {
          commit('loading');
          const entries = fromPairs(docs.map(doc => [doc.id, propsFn(props, doc).map(v => typeof v === 'undefined' ? '' : v)]));
          const index = { type, entries, props };
          const ref = buildRef(['brokers', bid, 'indexes', indexId]);
          await ref.set(index);
          commit('loaded', {index})
        } catch (error) {
          return Promise.reject(error);
        }
      }
    }
  }
}
