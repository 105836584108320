import Vue from 'vue';

let unsubscribe = null;

export default {
  namespaced: true,
  state: {
    loading: false,
    brokers: [],
    bid: null,
    loadError: null
  },
  getters: {
    brokers: state => state.brokers,
    bid: state => state.bid,
    broker: state => state.brokers.find(b => b.id === state.bid),
    loading: state => state.loading,
    loadError: state => state.loadError
  },
  mutations: {
    loading(state) {
      state.loading = true;
      state.loadError = null;
    },
    loaded(state) {
      state.loading = false;
    },
    brokerChange(state, { type, id, doc, newIndex, oldIndex }) {
      if (!state.bid) {
        state.bid = id;
      }
      state.loading = false;
      doc = { ...doc, id };
      if (type === 'added') {
        state.brokers.splice(newIndex, 0, doc);
      } else if (type === 'modified') {
        state.brokers.splice(oldIndex, 1);
        state.brokers.splice(newIndex, 0, doc);
      } else if (type === 'removed') {
        state.brokers.splice(oldIndex, 1);
      }
    },
    loadError(state, { error }) {
      state.loadError = error;
      state.loading = false;
    },
    reset(state) {
      state.brokers = [];
      state.loading = false;
    }
  },
  actions: {
    sub({ commit }, { uid }) {
      try {
        if (unsubscribe) unsubscribe();
        console.log('querying for', uid);
        unsubscribe = Vue.$db()
          .collection('brokers')
          .where('rw.' + uid, '==', true)
          .onSnapshot(
            ref => {
              ref.docChanges().forEach(change => {
                console.log('change');
                commit('brokerChange', {
                  type: change.type,
                  id: change.doc.id,
                  doc: change.doc.data(),
                  newIndex: change.newIndex,
                  oldIndex: change.oldIndex
                });
              });
            },
            error => {
              commit('loadError', { error });
              unsubscribe = null;
            }
          );
      } catch (error) {
        commit('loadError', { error });
      }
    },
    unsub({ commit }) {
      commit('reset');
      if (unsubscribe) {
        return unsubscribe();
      }
    },
    login: {
      root: true,
      handler({ dispatch }, { uid }) {
        return dispatch('sub', { uid });
      }
    },
    logout: {
      root: true,
      handler({dispatch}, { uid }) {
        return dispatch('unsub');
      }
    }
  }
};
