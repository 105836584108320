<template>
  <div id="app">
    <shell>
      <router-view />
    </shell>
    <!-- <update-banner v-if="!print"/> -->
    <!-- <nav-bar v-if="!print"/> -->
    <!-- <notifications v-if="!print"/> -->
    <!-- <nav-footer v-if="!print"/> -->
  </div>
</template>

<script>
import Shell from './components/Shell';
import Notifications from './components/Notifications';
import UpdateBanner from './components/UpdateBanner';
import NavBar from './components/NavBar';
import NavFooter from './components/NavFooter';

export default {
  name: 'App',
  components: { Shell, UpdateBanner, Notifications, NavBar, NavFooter },
  // mounted () {
  //   console.log('app');
  // },
  computed: {
    print () {
      return this.$route && this.$route.meta && this.$route.meta.print;
    }
  },
  metaInfo() {
    let title = '';
    let name = this.$route.name;
    if (name) {
      title = this.$t('route_' + name.split('-').join('_'));
    }
    return {
      title,
      titleTemplate: '%s - BRKR'
      // lang: this.$i18n.locale,
    };
  }
};
</script>
