export default {
  namespaced: true,
  state: {
    online: true
  },
  getters: {
    online: state => state.online
  },
  mutations: {
    updated(state, { online }) {
      state.online = online;
    }
  },
  actions: {
    init: {
      root: true,
      handler(context) {
        window.addEventListener("online", () => context.dispatch("update"));
        window.addEventListener("offline", () => context.dispatch("update"));
      }
    },
    update(context) {
      if (typeof window.navigator.onLine === "undefined") {
        context.commit("updated", { online: true });
      } else {
        context.commit("updated", { online: window.navigator.onLine });
      }
    }
  }
};
