<template>
  <aside>
    <nav
      class="navbar is-fixed-top has-shadow"
      v-if="!$route.meta.hideNav"
      @click="hide"
    >
      <div class="navbar-brand">
        <a class="navbar-item" href="/">
          <!-- <img src="../assets/logo.svg" label="BRKR"> -->
          <span
            class="has-text-weight-semibold"
            style="padding-left: 0.5em; color: black;"
            >BRKR</span
          >
        </a>

        <div
          class="navbar-burger burger"
          :class="{ 'is-active': active }"
          @click.stop="toggle"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div class="navbar-menu" :class="navbarMenuClass">
        <div class="navbar-start" v-if="authenticated">
          <nav-broker-picker />
          <item name="relations" label="navbar_relations" :bid="bid" v-if="bid"/>
          <item name="policies" label="navbar_policies" :bid="bid" v-if="bid"/>
          <item name="search-relations" label="navbar_search" :bid="bid" v-if="bid"/>

          <!-- <item name="campaigns" label="navbar_campaigns" /> -->
          <!-- <item name="insurers" label="navbar_insurers" /> -->


          <!--<Dropdown label="navbar_policies"></Dropdown>-->
          <!--<Dropdown label="navbar_offers"></Dropdown>-->
          <!--<Dropdown label="navbar_reminders"></Dropdown>-->
          <!--<Dropdown label="navbar_visits"></Dropdown>-->
          <!--<Dropdown label="navbar_claims"></Dropdown>-->
        </div>

        <div class="navbar-end">
          <div class="navbar-item">
            <language-picker></language-picker>
          </div>

          <sqr-router-link
            class="navbar-item"
            label="navbar_signin"
            to-name="sign-in"
            is-link
            v-if="!authenticated"
          />
          <div
            class="navbar-item has-dropdown is-hoverable"
            v-if="authenticated"
          >
            <a class="navbar-link is-arrowless">
              <figure class="image is-24x24" style="margin-right: 0.5rem;">
                <img class="is-rounded" :src="photoURL" />
              </figure>
              {{ displayName || $t('navbar_account') }}
            </a>
            <div class="navbar-dropdown is-right">
              <!-- <router-link
                class="navbar-item"
                :to="{name: 'subscription'}"
              >{{$t('navbar_subscription')}}</router-link> -->
              <!-- <router-link
                class="navbar-item"
                :to="{name: 'password-change'}"
              >{{$t('navbar_password_change')}}</router-link> -->
              <sqr-router-link
                class="navbar-item"
                label="navbar_signout"
                to-name="sign-out"
                is-link
              />
            </div>
          </div>
        </div>
      </div>
    </nav>
  </aside>
</template>

<style lang="scss">
.navbar.is-white .navbar-start > .navbar-item.shadow,
.navbar.is-white .navbar-start .navbar-link.shadow,
.navbar.is-white .navbar-start .navbar-item.shadow .navbar-link,
.navbar.is-white .navbar-end > .navbar-item.shadow,
.navbar.is-white .navbar-end .navbar-link.shadow {
  color: #888;
}
@media print {
  .navbar {
    display: none;
  }
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex';
import NavBrokerPicker from './NavBrokerPicker';
import Dropdown from './NavBarDropdown';
import Item from './NavBarItem';
import LanguagePicker from './LanguagePicker';

export default {
  name: 'nav-bar',
  components: {
    NavBrokerPicker,
    Dropdown,
    Item,
    LanguagePicker
  },
  data() {
    return { active: false };
  },
  computed: {
    ...mapGetters('auth', ['authenticated', 'displayName', 'photoURL']),
    ...mapGetters('user', ['roles', 'name', 'planning']),
    ...mapGetters('brokers', ['bid']),
    showMenu() {
      return this.authenticated;
    },
    promoMenu() {
      return this.sessionStatus === 'UNREGISTERED';
    },
    navbarMenuClass() {
      return { 'is-active': this.active };
    }
  },
  methods: {
    ...mapActions('auth', ['signOut']),
    toggle() {
      this.active = !this.active;
    },
    hide() {
      this.active = false;
    }
  }
};
</script>
