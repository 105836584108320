import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    removing: {},
    removeError: {}
  },
  mutations: {
    removing(state, { path }) {
      Vue.set(state.removing, path, true);
      Vue.delete(state.removeError, path);
    },
    error(state, { path, error }) {
      Vue.set(state.removeError, path, error);
    },
    finished(state, { path }) {
      Vue.delete(state.removing, path);
    }
  },
  actions: {
    async remove({ commit }, { path }) {
      try {
        commit('removing', { path });
        const res = await Vue.$db()
          .doc(path)
          .delete();
        return res;
      } catch (error) {
        commit('error', { path, error });
        return Promise.reject(error);
      } finally {
        commit('finished', { path });
      }
    }
  }
};
