import * as firebase from 'firebase/app';
import 'firebase/firebase-auth';
import 'firebase/firebase-auth/';
import 'firebase/firebase-functions';
import 'firebase/firebase-firestore';
import 'firebase/firebase-storage';
import 'firebase/firebase-messaging';

export default function(Vue) {
  // conf
  const app = firebase.initializeApp({
    apiKey: 'AIzaSyDtFyvZD_BccMaAzLUDz45cH0D_VsiAiSk',
    authDomain: 'sqrd-brkr.firebaseapp.com',
    databaseURL: 'https://sqrd-brkr.firebaseio.com',
    projectId: 'sqrd-brkr',
    storageBucket: 'sqrd-brkr.appspot.com',
    messagingSenderId: '951178794878',
    appId: '1:951178794878:web:527f28c3dc2d7a9a72852f'
  });

  app.auth().languageCode = 'en';

  if (process.env.VUE_APP_PUBLIC_VAPI_KEY) {
    try {
      const messaging = firebase.messaging();
      messaging.usePublicVapidKey(process.env.VUE_APP_PUBLIC_VAPI_KEY);
      Vue.$m = messaging;
    } catch (error) {
      console.log(error);
    }
  }

  Vue.prototype.$fb = () => app;
  Vue.$fb = () => app;

  Vue.prototype.$db = () => app.firestore();
  Vue.$db = () => app.firestore();

  Vue.prototype.$fs = () => app.storage();
  Vue.$fs = () => app.storage();

  const region = 'europe-west1';

  if (process.env.VUE_APP_FUNCTIONS) {
    app.functions(region).useFunctionsEmulator(process.env.VUE_APP_FUNCTIONS)
  }

  Vue.prototype.$f = name => app.functions(region).httpsCallable(name);
  Vue.$f = name => app.functions(region).httpsCallable(name);
}
