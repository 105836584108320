<template>
  <div class="navbar-item has-dropdown is-hoverable">
    <a class="navbar-link">
      <span class="icon" v-if="icon">
        <fa :icon="['fal', icon]"/>
      </span>
      <span class="picture" v-if="pictureUrl">
        <figure class="image is-24x24" v-if="pictureUrl">
          <img :src="pictureUrl">
        </figure>
      </span>
      <span>{{labelTr}}</span>
      <span class="loader" v-if="isLoading"></span>
    </a>
    <div class="navbar-dropdown" :class="dropdownClass">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .picture {
    margin-right: 0.8rem;
  }
</style>

<script>
  import label from '../sqrd/mixins/label';
  import tooltip from '../sqrd/mixins/tooltip';

  export default {
    name: 'navbar-dropdown',
    mixins: [label, tooltip],
    props: {
      icon: String,
      pictureUrl: String,
      isRight: Boolean,
      isLoading: Boolean
    },
    computed: {
      dropdownClass () {
        return {
          'is-right': this.isRight
        };
      }
    }
  };
</script>
