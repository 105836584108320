<template>
  <router-link
    :to="{ name, params: routeParams }"
    v-slot="{ href, route, navigate, isActive, isExactActive }"
  >
    <li :class="{ 'is-active': isExactActive }">
      <a :href="href" @click="navigate" :aria-current="isExactActive ? 'page': ''">
        <slot>
          {{labelTr}}
        </slot>
      </a>
    </li>
  </router-link>
</template>

<script>
import label from './mixins/label';
export default {
  name: 'SqrCrumb',
  mixins: [label],
  props: {
    name: String,
    params: {type: Object, default: () => ({})},
    bid: String,
    rid: String,
    pid: String,
    id: String
  },
  computed: {
    routeParams () {
      return Object.assign(this.params, {
        bid: this.bid,
        rid: this.rid,
        pid: this.pid,
        id: this.id
      });
    }
  }
};
</script>
