import Vue from 'vue';
import {flatten} from 'ramda';

export default function buildRef(...parts) {
  // parts [path], collection, id
  const fullpath = flatten(parts).filter(v => Boolean(v));
  // console.log('buildRef', fullpath);
  let query = Vue.$db();
  let col = true;
  fullpath.forEach((item, idx) => {
    query = col ? query.collection(item) : query.doc(item);
    col = !col;
  });
  return query;
};
