<template>
  <div v-if="status">
    <sqr-progress class="is-marginless" v-show="status === 'updateFound'"/>
    <div class="banner">
      <div class="container">
        <nav class="level">
          <!-- Left side -->
          <div class="level-left">
            <div class="level-item">
              <div class="title is-5">{{$t('update_banner_title')}}</div>
            </div>
            <div class="level-item">
              <div class="title is-6">{{$t('update_banner_' + status)}}</div>
            </div>
          </div>

          <!-- Right side -->
          <div class="level-right" v-show="status === 'updated'">
            <p class="level-item">
              <a class="button is-primary" @click="reload()">{{$t('update_banner_action')}}</a>
            </p>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<style scoped>
.banner {
  padding: 1rem 0 1rem 0;
  background-color: #edeaeb;
}
</style>

<script>
import { mapGetters } from 'vuex';
import SqrProgress from '@/sqrd/SqrProgress';
export default {
  name: 'update-banner',
  components: {
    SqrProgress
  },
  computed: {
    ...mapGetters('serviceWorker', ['status'])
  },
  methods: {
    reload() {
      console.log('reloading for update');
      window.location.reload(true);
    }
  }
};
</script>
