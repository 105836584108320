import Vue from 'vue';
import algoliasearch from 'algoliasearch/lite';

export default {
  namespaced: true,
  state: {
    loading: false,
    bid: '',
    token: ''
  },
  getters: {
    searchClient: state => {
      if (state.token) {
        return algoliasearch('9QYDFO61P1', state.token);
      } else {
        return null;
      }
    }
  },
  mutations: {
    tokenSet(state, { bid, token }) {
      state.token = token;
      state.bid = bid;
    }
  },
  actions: {
    async tokenGet({ state, commit }, { bid }) {
      if (state.bid === bid) return state.token;
      const res = await Vue.$f('search-tokenGet')({ bid });
      const { key } = res.data;
      commit('tokenSet', { bid, token: key });
      return key;
    },
    async searchClientGet({ state, dispatch }, { bid }) {
      await dispatch('tokenGet', { bid });
      return algoliasearch('9QYDFO61P1', state.token);
    }
  }
};
