import Vue from 'vue';
import { isNil, equals } from 'ramda';

export default function() {
  let unsubscribe = null;
  let pagesSnapshots = [];
  return {
    namespaced: true,
    state: {
      loading: false,
      loadError: null,

      path: undefined,
      pageSize: 10,
      pageSizes: [10, 25, 50, 100],
      where: [],
      orderBy: [],

      page: 0,
      totalPages: undefined,

      docs: []
    },
    getters: {
      pagination: state => ({
        page: state.page,
        pageSize: state.pageSize,
        currentPageSize: state.docs.length,
        pageSizes: state.pageSizes,
        totalPages: state.totalPages,
        currentTotalPages: pagesSnapshots.length,
        orderByField: state.orderByField,
        orderByDirection: state.orderByDirection
      })
    },
    mutations: {
      loading(state) {
        state.loading = true;
        state.loadError = null;
      },
      loaded(state) {
        state.loadError = null;
        state.loading = false;
      },
      reset(state, payload) {
        state.docs = payload?.docs ?? [];
      },
      fieldSet(state, { field, value }) {
        Vue.set(state, field, value);
      },

      docChange(state, { type, id, path, doc, newIndex, oldIndex }) {
        state.loading = false;
        doc = { ...doc, path, id };
        if (type === 'added') {
          state.docs.splice(newIndex, 0, doc);
        } else if (type === 'modified') {
          state.docs.splice(oldIndex, 1);
          state.docs.splice(newIndex, 0, doc);
        } else if (type === 'removed') {
          state.docs.splice(oldIndex, 1);
        }
      },
      loadError(state, { error }) {
        state.loading = false;
        state.loadError = error;
      }
    },
    actions: {
      async pageSet({ dispatch }, { path, page }) {
        console.log(path, page);
        return dispatch('sub', { page, path });
      },
      async pageSize({ dispatch }, { pageSize }) {
        return dispatch('sub', { pageSize, page: 0, reload: true });
      },
      async where({ dispatch }, { where }) {
        return dispatch('sub', { where });
      },
      async orderBy({ dispatch }, { orderBy }) {
        return dispatch('sub', { orderBy });
      },
      async sub(
        { state, commit },
        { path, page, pageSize, where, orderBy, reload }
      ) {

        if (!isNil(path) && !equals(path, state.path)) {
          commit('fieldSet', { field: 'path', value: path });
          reload = true;
          page = 0;
        }

        if (!isNil(pageSize) && !equals(pageSize, state.pageSize)) {
          commit('fieldSet', { field: 'pageSize', value: pageSize });
          reload = true;
          page = 0;
        }

        if (!isNil(where) && !equals(where, state.where)) {
          commit('fieldSet', { field: 'where', value: where });
          reload = true;
          page = 0;
        }

        if (!isNil(orderBy) && !equals(orderBy, state.orderBy)) {
          commit('fieldSet', { field: 'orderBy', value: orderBy });
          reload = true;
          page = 0;
        }

        console.log(page, pagesSnapshots.length, page <= pagesSnapshots.length);

        if (reload || typeof page === 'undefined') {
          page = 0;
          pagesSnapshots = [];
        }

        if (page <= pagesSnapshots.length) {
          reload = true;
        }

        if (!reload) return;

        if (unsubscribe) {
          unsubscribe();
        }



        console.log(page, pagesSnapshots.length, page <= pagesSnapshots.length);
        commit('loading');
        try {
          path = path || state.path;
          const ref = Vue.$db().collection(path);
          const selected = state.where.reduce(
            (query, conds) => query.where(...conds),
            ref
          );
          const sorted = state.orderBy.reduce(
            (query, order) => query.orderBy(...order),
            selected
          );
          const limitted = sorted.limit(state.pageSize);
          let paged = limitted;

          if (pagesSnapshots.length === 0) {
            // do nothing, this is the first page
            paged = limitted;
            page = 0;
          } else if (page === pagesSnapshots.length) {
            // this page has not been loaded yet
            const pageSnapshot = pagesSnapshots[page - 1];
            const docs = pageSnapshot.docs;
            const docLast = docs[docs.length - 1];
            paged = paged.startAfter(docLast);
          } else {
            // this page was loaded before
            const docFirst = pagesSnapshots[page].docs[0];
            paged = paged.startAt(docFirst);
          }

          commit('reset', { docs: [] });
          commit('fieldSet', { field: 'page', value: page });

          unsubscribe = paged.onSnapshot(
            ref => {
              pagesSnapshots[page] = ref;

              if (ref.empty) {
                // no record, we have one less page
                commit('fieldSet', {
                  field: 'totalPages',
                  value: page || 1
                });
                commit('reset', { docs: [] });
                commit('loaded');
                return;
              } else if (
                !state.totalPages &&
                ref.docs.length < state.pageSize
              ) {
                // we reached last page
                commit('fieldSet', {
                  field: 'totalPages',
                  value: page + 1
                });
              } else {
                commit('fieldSet', {
                  field: 'totalPages',
                  value: undefined
                });
              }

              ref.docChanges().forEach(change => {
                commit('docChange', {
                  type: change.type,
                  id: change.doc.id,
                  path: change.doc.ref.path,
                  doc: change.doc.data(),
                  newIndex: change.newIndex,
                  oldIndex: change.oldIndex
                });
              });
            },
            error => commit('loadError', { error })
          );
        } catch (error) {
          commit('loadError', { error });
          return Promise.reject(error);
        }
      },
      async unsub() {
        if (unsubscribe) {
          unsubscribe();
        }
        unsubscribe = null;
      },
      async remove({ commit }, { path }) {
        try {
          commit('removing');
          return await Vue.$db()
            .doc(path)
            .delete();
        } catch (error) {
          commit('removeError', { path, error });
        } finally {
          commit('removed');
        }
      }
    }
  };
}
