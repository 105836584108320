<template>
  <progress
    class="progress is-small is-primary is-radiusless is-bold"
    v-bind="$attrs"
  />
</template>

<script>
export default {
  name: 'SqrProgress'
};
</script>

