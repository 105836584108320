export default {
  props: {
    label: String,
    labelRaw: String
  },
  computed: {
    labelTr() {
      return this.labelRaw ? this.labelRaw : this.$t(this.label);
    },
    labelClass() {
      return {};
    }
  }
};
