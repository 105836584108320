<template>
  <div class="notifications">
    <transition-group name="fade" enter-active-class="fadeInDown" leave-active-class="fadeOutDown">
      <Notification v-for="n in notifications" :key="n.id" :notification="n" :color="n.color"/>
    </transition-group>
  </div>
</template>

<style scoped>
.notifications {
  position: fixed;
  top: 4rem;
  right: 4rem;
  z-index: 10;
  width: 20rem;
}
</style>

<script>
import { mapGetters } from 'vuex';
import Notification from './Notification';

export default {
  name: 'Notifications',
  components: { Notification },
  computed: {
    ...mapGetters('notifications', ['notifications'])
  }
};
</script>
