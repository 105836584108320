import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import online from './stores/online';
import serviceWorker from './stores/serviceWorker';

import auth from './stores/auth';
import search from './stores/search';
import language from './stores/language';
import notifications from './stores/notifications';
import brokers from './stores/brokers';
import records from './stores/records';
import docs from './stores/docs';
import docsPaged from './stores/docsPaged';
import doc from './stores/doc';
import updater from './stores/updater';
import remover from './stores/remover';

// import recordsMore from './stores/recordsMore';
import recordsPage from './stores/recordsPage';
import recordLive from './stores/recordLive';
import prospection from './stores/prospection';
import recordsIndex from './stores/recordsIndex';
import emailAttachments from './stores/emailAttachments';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    online,
    serviceWorker,
    notifications,
    language,
    auth,
    brokers,
    relations: recordsPage({}),
    relation: recordLive('relations'),

    policies: recordsPage({}),
    policy: recordLive('policies'),

    emailsTemplates: recordsPage({}),
    emailsTemplate: recordLive(),
    emails: recordsPage({}),
    emailAttachments,

    offers: docs(),
    offer: recordLive(),

    inquiries: docsPaged(),
    inquiry: doc(),
    inquiryModels: docs(),
    inquiryModel: doc(),

    campaigns: docs(),
    campaign: doc(),

    // user,
    insurers: records('insurers'),
    branches: records('branches'),

    settings: recordLive(),

    prospection,

    relationsIndex: recordsIndex({ id: 'relations' }),
    policiesIndex: recordsIndex({ id: 'policies', threshold: 0, distance: 0 }),
    search,
    updater,
    remover
  },
  plugins: [
    createPersistedState({ key: 'vuex-brkr', paths: ['language'] })
  ],
  strict: process.env.NODE_ENV !== 'production'
});

export default store;
