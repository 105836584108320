<template>
  <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:xl="http://www.w3.org/1999/xlink"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 340.15748 340.15748"
    width="340.15748"
    height="340.15748"
  >
    <defs />
    <metadata>
      Produced by OmniGraffle 7.9.4
      <dc:date>2019-02-14 10:38:12 +0000</dc:date>
    </metadata>
    <g
      id="BRKR"
      fill="none"
      stroke="none"
      stroke-dasharray="none"
      fill-opacity="1"
      stroke-opacity="1"
    >
      <title>BRKR</title>
      <g id="BRKR: Logo">
        <title>Logo</title>
        <g id="Graphic_3">
          <title>square</title>
          <rect
            x="0"
            y="0"
            width="340.15748"
            height="340.15748"
            fill="#3d2573"
          />
        </g>
        <g id="Group_36">
          <title>BRKR</title>
          <g id="Graphic_32">
            <path
              d="M 127.73308 293.90914 L 127.73308 273.26914 L 133.21457 273.26914 C 134.62915 273.26914 135.92584 273.42914 137.10466 273.74914 C 138.28348 274.06914 139.3002 274.62913 140.15484 275.42914 C 141.00948 276.22914 141.6873 277.28513 142.1883 278.59714 C 142.68929 279.90915 142.93979 281.55713 142.93979 283.54114 C 142.93979 287.50916 141.99674 290.22913 140.11063 291.70114 C 138.22452 293.17315 135.74903 293.90914 132.6841 293.90914 Z M 127.73308 263.66914 L 127.73308 245.71714 L 132.33046 245.71714 C 135.51327 245.71714 137.8414 246.38913 139.31493 247.73314 C 140.78846 249.07715 141.52521 251.38112 141.52521 254.64514 C 141.52521 257.90915 140.71478 260.22913 139.0939 261.60514 C 137.47303 262.98115 134.8944 263.66914 131.35794 263.66914 Z M 115.00189 235.54114 L 115.00189 304.08514 L 136.48578 304.08514 C 140.19906 304.08514 143.30815 303.54114 145.81315 302.45314 C 148.31814 301.36513 150.35157 299.90915 151.91351 298.08514 C 153.47545 296.26113 154.58057 294.13315 155.22892 291.70114 C 155.87728 289.26913 156.20145 286.70915 156.20145 284.02114 C 156.20145 279.4131 155.17 275.73315 153.10706 272.98114 C 151.04412 270.22912 148.24447 268.59714 144.708 268.08514 L 144.708 267.89314 C 147.89082 266.93313 150.2779 265.12515 151.8693 262.46914 C 153.46071 259.81313 154.2564 256.62916 154.2564 252.91714 C 154.2564 249.65312 153.81435 246.90115 152.93024 244.66114 C 152.04612 242.42113 150.80838 240.62915 149.21697 239.28514 C 147.62556 237.94113 145.72475 236.98114 143.51446 236.40514 C 141.30417 235.82914 138.90236 235.54114 136.30896 235.54114 Z"
              fill="white"
            />
          </g>
          <g id="Graphic_33">
            <path
              d="M 168.37789 235.54114 L 168.37789 304.08514 L 181.60085 304.08514 L 181.60085 275.09314 L 188.67145 275.09314 C 191.42625 275.09314 193.38519 275.86113 194.54832 277.39714 C 195.71146 278.93315 196.35424 281.04513 196.47667 283.73314 L 196.84398 296.88514 C 196.9052 298.22915 197.02763 299.52513 197.21128 300.77314 C 197.39493 302.02114 197.82345 303.12513 198.49685 304.08514 L 212.82172 304.08514 L 212.82172 303.50914 C 211.59736 302.80513 210.80154 301.49315 210.43424 299.57314 C 210.18937 297.65313 210.00572 294.86916 209.88328 291.22114 C 209.82206 289.36513 209.76085 287.68515 209.69963 286.18114 C 209.6384 284.67713 209.54659 283.28514 209.42415 282.00514 C 209.05684 278.16512 208.10798 275.38115 206.57754 273.65314 C 205.0471 271.92513 202.62904 270.80514 199.32328 270.29314 L 199.32328 270.10114 C 203.05756 269.26913 205.81232 267.39715 207.58763 264.48514 C 209.36294 261.57312 210.25058 257.81316 210.25058 253.20514 C 210.25058 247.2531 208.72017 242.82115 205.65928 239.90914 C 202.5984 236.99712 198.31322 235.54114 192.80363 235.54114 Z M 181.60085 265.49314 L 181.60085 245.71714 L 187.84502 245.71714 C 193.9668 245.71714 197.02763 248.8851 197.02763 255.22114 C 197.02763 258.93316 196.1706 261.57313 194.4565 263.14114 C 192.7424 264.70915 190.29373 265.49314 187.1104 265.49314 Z"
              fill="white"
            />
          </g>
          <g id="Graphic_34">
            <path
              d="M 235.5779 235.54114 L 221.7539 235.54114 L 221.7539 304.08514 L 235.5779 304.08514 L 235.5779 283.73314 L 240.6659 275.95714 L 254.7779 304.08514 L 270.3299 304.08514 L 249.5939 264.91714 L 268.2179 235.54114 L 253.2419 235.54114 L 235.7699 262.99714 L 235.5779 262.99714 Z"
              fill="white"
            />
          </g>
          <g id="Graphic_35">
            <path
              d="M 273.3059 235.54114 L 273.3059 304.08514 L 286.52885 304.08514 L 286.52885 275.09314 L 293.59945 275.09314 C 296.35425 275.09314 298.3132 275.86113 299.47632 277.39714 C 300.63946 278.93315 301.28224 281.04513 301.40467 283.73314 L 301.77198 296.88514 C 301.8332 298.22915 301.95563 299.52513 302.13928 300.77314 C 302.32293 302.02114 302.75145 303.12513 303.42485 304.08514 L 317.7497 304.08514 L 317.7497 303.50914 C 316.52536 302.80513 315.72954 301.49315 315.36224 299.57314 C 315.11737 297.65313 314.9337 294.86916 314.81128 291.22114 C 314.75006 289.36513 314.68885 287.68515 314.62763 286.18114 C 314.5664 284.67713 314.4746 283.28514 314.35215 282.00514 C 313.98484 278.16512 313.036 275.38115 311.50554 273.65314 C 309.9751 271.92513 307.55704 270.80514 304.25128 270.29314 L 304.25128 270.10114 C 307.98556 269.26913 310.7403 267.39715 312.51563 264.48514 C 314.29094 261.57312 315.1786 257.81316 315.1786 253.20514 C 315.1786 247.2531 313.64817 242.82115 310.58728 239.90914 C 307.5264 236.99712 303.24122 235.54114 297.73163 235.54114 Z M 286.52885 265.49314 L 286.52885 245.71714 L 292.77302 245.71714 C 298.8948 245.71714 301.95563 248.8851 301.95563 255.22114 C 301.95563 258.93316 301.0986 261.57313 299.3845 263.14114 C 297.6704 264.70915 295.22173 265.49314 292.0384 265.49314 Z"
              fill="white"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Logo'
};
</script>
