export default {
  props: {
    color: String,
    isWhite: Boolean,
    isBlack: Boolean,
    isLight: Boolean,
    isDark: Boolean,
    isPrimary: Boolean,
    isLink: Boolean,
    isInfo: Boolean,
    isSuccess: Boolean,
    isWarning: Boolean,
    isDanger: Boolean
  },
  computed: {
    colorClass() {
      if (this.color) return { ["is-" + this.color]: true };
      if (this.isWhite) return { "is-white": true };
      if (this.isBlack) return { "is-black": true };
      if (this.isLight) return { "is-light": true };
      if (this.isDark) return { "is-dark": true };
      if (this.isPrimary) return { "is-primary": true };
      if (this.isLink) return { "is-link": true };
      if (this.isInfo) return { "is-info": true };
      if (this.isSuccess) return { "is-success": true };
      if (this.isWarning) return { "is-warning": true };
      if (this.isDanger) return { "is-danger": true };
    }
  }
};
