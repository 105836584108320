import Vue from 'vue';
import { isNil, equals } from 'ramda';

export default function() {
  let unsubscribe = null;
  return {
    namespaced: true,
    state: {
      loading: false,
      loadError: null,

      path: undefined,
      where: [],
      orderBy: [],
      limit: 0,

      docs: []
    },
    mutations: {
      loading(state) {
        state.loading = true;
        state.loadError = null;
      },
      loaded(state) {
        state.loadError = null;
        state.loading = false;
      },
      reset(state, payload) {
        state.docs = payload?.docs ?? [];
      },
      fieldSet(state, { field, value }) {
        Vue.set(state, field, value);
      },

      docChange(state, { type, id, path, doc, newIndex, oldIndex }) {
        state.loading = false;
        doc = { ...doc, path, id };
        if (type === 'added') {
          state.docs.splice(newIndex, 0, doc);
        } else if (type === 'modified') {
          state.docs.splice(oldIndex, 1);
          state.docs.splice(newIndex, 0, doc);
        } else if (type === 'removed') {
          state.docs.splice(oldIndex, 1);
        }
      },
      loadError(state, { error }) {
        state.loading = false;
        state.loadError = error;
      }
    },
    actions: {
      async where({ dispatch }, { where }) {
        return dispatch('sub', { where });
      },
      async orderBy({ dispatch }, { orderBy }) {
        return dispatch('sub', { orderBy });
      },
      async sub(
        { state, commit },
        { path, where, orderBy, limit, reload }
      ) {
        if (!isNil(path) && !equals(path, state.path)) {
          commit('fieldSet', { field: 'path', value: path });
          reload = true;
        }

        if (!isNil(where) && !equals(where, state.where)) {
          commit('fieldSet', { field: 'where', value: where });
          reload = true;
        }

        if (!isNil(orderBy) && !equals(orderBy, state.orderBy)) {
          commit('fieldSet', { field: 'orderBy', value: orderBy });
          reload = true;
        }

        if (!isNil(limit) && !equals(limit, state.limit)) {
          commit('fieldSet', { field: 'limit', value: limit });
          reload = true;
        }

        if (!reload) return;
        if (unsubscribe) unsubscribe();

        commit('loading');
        try {
          const ref = Vue.$db().collection(path);
          const selected = state.where.reduce(
            (query, conds) => query.where(...conds),
            ref
          );
          const sorted = state.orderBy.reduce(
            (query, order) => query.orderBy(...order),
            selected
          );
          const limitted = state.limit ? sorted.limit(state.limit) : sorted;
          const paged = limitted;

          commit('reset', { docs: [] });
          unsubscribe = paged.onSnapshot(
            ref => {
              commit('loaded');
              ref.docChanges().forEach(change => {
                commit('docChange', {
                  type: change.type,
                  id: change.doc.id,
                  path: change.doc.ref.path,
                  doc: change.doc.data(),
                  newIndex: change.newIndex,
                  oldIndex: change.oldIndex
                });
              });
            },
            error => commit('loadError', { error })
          );
        } catch (error) {
          commit('loadError', { error });
          return Promise.reject(error);
        }
      },
      async unsub() {
        if (unsubscribe) {
          unsubscribe();
        }
        unsubscribe = null;
      }
    }
  };
}
