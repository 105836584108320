<template>
  <footer class="footer no-print">
    <div class="container">
      <div class="content has-text-right">
        <p>
          <span class="has-text-weight-semibold">BRKR</span>
          {{version}}
          {{$t('footer_from')}} <span class="tooltip" :data-tooltip="buildDateFull">{{buildDate | date}}</span>
          {{$t('footer_by')}} <a href="https://sqrd.com/" class="has-text-weight-semibold has-text-dark" tabindex="-1">SQRD</a>,
          {{$t('footer_tos')}}.
        </p>
      </div>
    </div>
  </footer>
</template>

<style scoped>
  footer.footer {
    /* margin-top: 6rem; */
  }
  @media print {
    .no-print {
      display: none;
    }
  }
</style>

<script>
  import {mapGetters} from 'vuex';
  import date from '../filters/date';

  export default {
    name: 'nav-footer',
    filters: {
      date
    },
    data () {
      return {
        version: process.env.VUE_APP_VERSION,
        buildDate: process.env.VUE_APP_BUILD_DATE,
        buildDateFull: process.env.VUE_APP_BUILD_DATE_FULL
      };
    },
    computed: {
      ...mapGetters('auth', ['username']),
    },
  };
</script>
