export default function(error) {
  if (!error) return '';
  if (error.response && error.response.data && error.response.data.message) {
    return error.response.data.message;
  } else if (error.message) {
    return error.message;
  } else {
    return 'N/A';
  }
}
