// import './plugins/sentry';
console.log('BRKR version', process.env.VUE_APP_VERSION);
// console.log('BRKR release', process.env.VUE_APP_SENTRY_RELEASE);

import Vue from 'vue';
Vue.config.productionTip = false;

import './assets/tailwind.css';
import './main.scss';

import App from './App.vue';
import './registerServiceWorker';

import firebase from './plugins/firebase';
Vue.use(firebase);

import InstantSearch from 'vue-instantsearch';
Vue.use(InstantSearch);

import fa from './plugins/fontawesome';
Vue.use(fa);

import i18n from './plugins/i18n';
Vue.use(i18n, {
  // fallbackLocale: 'en',
  silentTranslationWarn: true
});

import router from './router';
Vue.$router = router;
import store from './store';
Vue.$store = store;

import SqrProgress from '@/sqrd/SqrProgress';
Vue.component('SqrProgress', SqrProgress);

import SqrHero from './sqrd/SqrHero';
Vue.component('SqrHero', SqrHero);

import SqrError from './sqrd/SqrError';
Vue.component('SqrError', SqrError);

import SqrErrorNote from './sqrd/SqrErrorNote';
Vue.component('SqrErrorNote', SqrErrorNote);

import SqrButton from './sqrd/SqrButton';
Vue.component('SqrButton', SqrButton);

import SqrCrumb from './sqrd/SqrCrumb';
Vue.component('SqrCrumb', SqrCrumb);

import SqrRouterLink from './sqrd/SqrRouterLink';
Vue.component('SqrRouterLink', SqrRouterLink);

import SqrPageHeader from '@/sqrd-ui/SqrPageHeader';
Vue.component('SqrPageHeader', SqrPageHeader);

import meta from 'vue-meta';
Vue.use(meta);

import vuelidate from 'vuelidate';
Vue.use(vuelidate);

store.dispatch('init', {});

new Vue({ router, store, i18n: Vue.$locale, render: h => h(App) }).$mount(
  '#app'
);

window.Vue = Vue;
