import Vue from 'vue';
import { equals } from 'ramda';
import buildRef from '@/utils/buildRef';

export default function(collectionBase) {
  let unsubscribe = null;
  return {
    namespaced: true,
    state: {
      path: [],
      loading: false,
      records: [],
      loadError: null,
      where: [],
      orderBy: [],
      limit: 100,

      removing: {}
    },
    getters: {
      records: state => state.records,

      loading: state => state.loading,
      loadError: state => state.loadError,

      removing: state => state.removing,

      error: state => state.loadError
    },
    mutations: {
      logout(state) {
        state.records = [];
        state.loading = false;
        state.removing = {};
        state.error = null;
      },
      pathSet(state, { path }) {
        state.path = path;
        state.records = [];
      },
      whereSet(state, { where }) {
        state.where = where;
        state.records = [];
      },
      orderBySet(state, { orderBy }) {
        state.orderBy = orderBy;
        state.records = [];
      },
      limitSet(state, { limit }) {
        state.limit = limit;
      },
      loading(state) {
        state.loading = true;
        state.loadError = null;
      },
      loaded(state, { records }) {
        state.records = records;
        state.loading = false;
      },
      recordChange(state, { type, id, doc, newIndex, oldIndex }) {
        state.loading = false;
        doc = { ...doc, id };
        if (type === 'added') {
          state.records.splice(newIndex, 0, doc);
          // if we want to handle references we would do it here
        } else if (type === 'modified') {
          // remove the old one first
          state.records.splice(oldIndex, 1);
          // if we want to handle references we would have to unsubscribe
          // from old references' listeners and subscribe to the new ones
          state.records.splice(newIndex, 0, doc);
        } else if (type === 'removed') {
          state.records.splice(oldIndex, 1);
          // if we want to handle references we need to unsubscribe
          // from old references
        }
      },
      loadError(state, { error }) {
        state.loadError = error;
        state.loading = false;
      },
      removing(state, { id }) {
        state.removing[id] = true;
      },
      removed(state, { id }) {
        delete state.removing[id];
      },
      removeError(state, { id }) {
        // { …, error }
        delete state.removing[id];
      }
    },
    actions: {
      async sub({ state, commit }, { path, where, orderBy, limit, reload }) {
        try {
          commit('loading');

          if (path && !equals(path, state.path)) {
            commit('pathSet', { path });
          }

          if (where && !equals(where, state.where)) {
            commit('whereSet', { where });
          }

          if (orderBy && !equals(orderBy, state.orderBy)) {
            commit('orderBySet', { orderBy });
          }

          if (limit && !equals(limit, state.limit)) {
            commit('limitSet', { limit });
          }

          let query = buildRef(path, collectionBase);

          if (state.where) {
            state.where.forEach(w => {
              query = query.where(...w);
            });
          }

          if (state.orderBy) {
            state.orderBy.forEach(q => {
              query = query.orderBy(...q);
            });
          }

          if (state.limit) {
            query = query.limit(state.limit);
          } else {
            query = query.limit(5);
          }

          const qs = await query.get();
          const records = qs.docs.map(snap => ({
            ...snap.data(),
            id: snap.id
          }));

          commit('loaded', { records });
          return records;
        } catch (error) {
          commit('loadError', { error });
          return Promise.reject(error);
        }
      },
      async unsub(context, payload) {
        if (!unsubscribe) return;
        unsubscribe();
        unsubscribe = null;
      },
      async remove(context, { id }) {
        try {
          return Vue.$db()
            .collection(collection)
            .doc(id)
            .delete();
        } catch (error) {
          console.log(error);
          context.commit('removeError', { id, error });
          context.dispatch('removeError', { error }, { root: true });
        }
      },
      // login: {
      //   root: true,
      //   handler(context, {user}) {
      //     context.dispatch('sub', { reload: true });
      //   }
      // },
      logout: {
        root: true,
        handler(context) {
          context.commit('logout', {});
          context.dispatch('unsub');
        }
      }
    }
  };
}
