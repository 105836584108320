import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    attachments: []
  },
  getters: {},
  mutations: {
    clean(state) {
      state.attachments = [];
    },
    add (state, {attachments}) {
      state.attachments = [...state.attachments, ...attachments];
    },
    replace (state, {attachments}) {
      state.attachments = attachments;
    }
  },
  actions: {
    async downloadURLs({state, commit}) {
      try {
        const attachments = await Promise.all(state.attachments.map(async (att) => {
          const url = await Vue.$fs().ref(att.fullPath).getDownloadURL();
          return {...att, url};
        }));
        commit('replace', {attachments});
      } catch (error) {
        console.log(error);
      }
    }
  },
};
