<template>
  <div class="h-screen flex flex-col justify-center bg-gray-200">
    <div class="max-w-lg mx-auto">
      <router-link :to="{ name: 'sign-up' }" class="block w-full">
        <sqr-button
          icon="sign-in"
          label="Créer un compte"
          color="primary"
          size="xl"
          class="w-full text-left"
        />
      </router-link>

      <router-link :to="{ name: 'sign-in' }" class="block mt-4 w-full">
        <sqr-button
          icon="sign-in"
          label="Se connecter"
          color="white"
          size="xl"
          class="w-full text-left"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SqrButton from '@/sqrd-ui/SqrButton';

export default {
  name: 'Home',
  components: { SqrButton },
  computed: {
    ...mapGetters('auth', ['authenticated'])
  },
  mounted() {
    if (this.authenticated) {
      this.$router.replace({ name: 'brokers' });
    }
  },
  watch(authenticated) {
    if (authenticated) {
      this.$router.replace({ name: 'brokers' });
    }
  }
};
</script>
