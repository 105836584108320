// import { library } from '@fortawesome/fontawesome-svg-core';
// import { faGoogle } from '@fortawesome/free-brands-svg-icons';
// import { fal } from '@fortawesome/pro-light-svg-icons';
// import { far } from '@fortawesome/pro-regular-svg-icons';
// import {
//   FontAwesomeIcon,
//   FontAwesomeLayers,
//   FontAwesomeLayersText
// } from '@fortawesome/vue-fontawesome';

export default function(Vue) {
  // options
  // library.add(fal);
  // library.add(far);
  // library.add({ faGoogle });

  // TODO mock icons
  // Vue.component('fa', FontAwesomeIcon);
  // Vue.component('fal', FontAwesomeLayers);
  // Vue.component('falt', FontAwesomeLayersText);
}
